import { Icon } from 'semantic-ui-react';
import Information from './information';
import Insurance from './insurence';
import Vouchers from './vouchers';
import Reviews from './reviews';
import Payments from './payments';

import Footer from '../footer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth, firestore, storage } from '../fire';
import './index.css'
import Strings from '../strings'
import { withRouter } from 'react-router-dom'
import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
class index extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        menu: [{ key: 'info', value: 'Information', icon: 'user outline' },
        { key: 'insurnce', value: 'Insurance', icon: 'address card outline' },
        { key: 'voucher', value: 'Vouchers', icon: 'percent' },
        { key: 'reviews', value: 'Feedback', icon: 'chat' },
        // { key: 'favourite', value: 'Favourite', icon: 'heart' },
        { key: 'payment', value: 'Payment', icon: 'credit card outline' },
        { key: 'lang', value: 'Language', icon: 'globe' },
        { key: 'logout', value: 'Logout', icon: 'sign-out' }],
        activeMenu: false

    }
    componentDidMount() {
        let { user } = this.props
        const { history } = this.props

        if (!user) {
            history.replace('/login')
        }
    }
    render() {
        let { activeMenu, menu } = this.state
        let { user, providers, nationalities, history } = this.props
        if (!user) {
            return ''
        }
        return (
            <div>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)', boxShadow: 'none', color: '#fff', fontFamily: 'tfBold' }}>
                    {
                        activeMenu && <div style={{ width: '50px' }} onClick={() => this.setState({ activeMenu: false })}>{Strings.Back} </div>
                    }
                    <div style={{ textAlign: 'center', width: '100%', fontFamily: 'tfBold', fontSize: '16px' }}>{Strings.Profile} </div>
                    {
                        activeMenu && <div style={{ width: '50px' }}></div>
                    }

                </div>
                <div style={{ display: 'flex', backgroundColor: 'var(--themeColor)', color: 'white', padding: '0 16px 16px', alignItems: 'center', position: 'sticky', top: '51px', zIndex: '99' }}>
                    <div onClick={() => this.inputFile.click()}>
                        {
                            user?.profilePicture ?
                                <img style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px' }} src={user?.profilePicture} />
                                :
                                <Icon style={{ fontSize: '60px', display: 'flex', alignItems: 'center' }} name='users' />
                        }
                    </div>
                    <input type='file' id='file' ref={(e) => this.inputFile = e} style={{ display: 'none' }} onChange={async (e) => {
                        if (e.target.files?.length > 0) {
                            // console.log('user.uid', user)
                            let resizedImg = await resizeFile(e.target.files[0]);
                            let task = await storage().ref().child('users').child(user.uid).putString(resizedImg, 'data_url');
                            let profilePicture = await task.ref.getDownloadURL()
                            firestore().collection('users').doc(user.uid).update({ profilePicture })
                        }
                    }}
                    />
                    <div style={{ padding: '0 12px', textAlign: 'initial' }}>
                        <div style={{ fontSize: '22px', fontFamily: 'tfBold' }}>
                            {Strings.Hi} {user?.firstName}
                        </div>
                        <div>
                            {Strings.bookingConfirmed}  {user?.userBookings?.filter(rec => ['new', 'accepted', 'arrive'].includes(rec.status)).length}
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: 'calc(100vh - 185px)', textAlign: 'initial', alignItems: 'center' }}>
                    {
                        activeMenu === 'info' ?
                            <Information user={user} insurnces={providers?.insurnces} nationalities={nationalities} dismiss={() => this.setState({ activeMenu: false })} />
                            : activeMenu === 'insurnce' ?
                                <Insurance user={user} insurnces={providers?.insurnces} />
                                : activeMenu === 'voucher' ?
                                    <Vouchers user={user} facilities={providers?.facilities} />
                                    : activeMenu === 'reviews' ?
                                        <Reviews user={user} doctors={providers?.providers} />
                                        : activeMenu === 'payment' ?
                                            <Payments user={user} />

                                            : activeMenu === 'lang' ?
                                                this.langSwitch()

                                                : menu.map(item => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                if (item.key === 'logout') {
                                                                    auth().signOut()
                                                                    history.replace('/login')


                                                                } else {
                                                                    this.setState({ activeMenu: item.key })
                                                                }
                                                            }}
                                                            key={item.key}
                                                            style={{
                                                                padding: '12px',
                                                                display: 'flex',
                                                                fontSize: '16px',
                                                                borderBottom: '1px solid #eae8e8',
                                                                cursor: 'pointer'
                                                            }}>
                                                            <Icon name={item.icon} style={{ color: 'var(--themeColor)', fontSize: '16px' }} />
                                                            <div style={{ padding: "0 4px" }}> {Strings[item.value]}</div>
                                                        </div>
                                                    )
                                                })
                    }
                </div>
                {
                    !activeMenu && <Footer />
                }
            </div>
        );
    }
    langSwitch = () => {
        return (
            <div style={{}}>
                <div onClick={() => this.switchLanguage('en')} style={{ padding: '12px 8px', fontFamily: 'tfMedium', fontSize: '16px', cursor: 'pointer', borderBottom: '1px solid #cacaca30' }}>
                    English
                </div>
                <div onClick={() => this.switchLanguage('ar')} style={{ padding: '12px 8px', fontFamily: 'tfMedium', fontSize: '16px', cursor: 'pointer' }}>
                    Arabic
                </div>

            </div>
        )
    }
    switchLanguage = (lang) => {

        localStorage.setItem('lang', lang)
        Strings.setLanguage(lang);
        document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr'
        this.setState({ activeMenu: false })
    }
}
const mapStateToProps = ({ user, providers, helpers }) => {
    console.log("user", user)
    return { user: user?.user, providers, nationalities: helpers?.helpers?.nationalities }
}
export default connect(mapStateToProps, {})(withRouter(index));