import React, { Component } from 'react';
import Nearby from './nearby/nearby'
import RecentViewd from './recentViewd/recentViewd'
import TopSpecs from './topSpecs/topSpecs'
import { connect } from "react-redux";
import { Icon, Input } from 'semantic-ui-react'
import Footer from '../footer'
import { Link } from 'react-router-dom';
import { getName } from '../helpers'
import Strings from '../strings';
import PowerdBy from '../assets/powerdbyGray.png'
import TestPowerdBy from '../assets/footer.jpeg'

function getResults(searchKey, array, flag) {
    let optimizedSearchKey = searchKey.replace(/ /g, '').toLowerCase()
    return array.filter(rec => rec[flag]?.replace(/ /g, '').toLowerCase().includes(optimizedSearchKey))
}
class Home extends Component {
    componentDidMount() {
        navigator.geolocation.getCurrentPosition(pos => {
            let coords = pos?.coords
            if (coords) {
                this.setState({
                    userLocation: { latitude: coords.latitude, longitude: coords.longitude }
                })
            }

        })
    }
    state = {
        search: new URL(window.location.href).searchParams.has('search')
    }
    render() {
        let { providers, user } = this.props
        let { search, userLocation } = this.state
        if (search) {
            return this.renderSearch()
        }
        let test = process.env.REACT_APP_NAME === 'test'
        return (
            <div >
                <div style={{minHeight:'100%'}}>
                    <div className='App-header'>
                        <img style={{ width: test ? '40px' : '60px', height: '40px', objectFit: 'contain' }} src={`${process.env.REACT_APP_NAME}/logo.png`} />
                        <div style={{ fontFamily: 'tfBlack', color: 'var(--themeColor)' }}>{process.env.REACT_APP_TITLE}</div>
                        <div style={{ width: test ? '40px' : '60px', display: 'flex', justifyContent: 'flex-end' }} onClick={() => this.setState({ search: !search })}><Icon name='search' /></div>
                    </div>
                    <div style={{ padding: '8px 0' }}>
                        <Nearby facilities={providers?.facilities} userLocation={userLocation} />
                        <RecentViewd doctors={providers?.providers} facilities={providers?.facilities} specalities={providers?.specalities} user={user} />
                        {
                            process.env.REACT_APP_NAME !== 'saeed' ?
                                <TopSpecs topSpecalties={providers?.topSpecalties} />
                                : ''
                        }
                    </div>
                    {
                        process.env.REACT_APP_NAME !== 'doctory' ?
                            <Link to={{ pathname: 'https://sinou.io' }} target="_blank">
                                <img style={{ width: '50%' }} src={TestPowerdBy} />

                            </Link>
                            :
                            <img style={{ width: '50%', padding: '18px' }} src={PowerdBy} />
                    }
                </div>

                <Footer />
            </div>
        );
    }
    renderSearch() {
        let { providers } = this.props
        let array = [{ key: 'Top Specialties', array: providers?.topSpecalties, flag: 'specialty' }]
        let { search, searchKey } = this.state

        if (searchKey) {
            array = []
            let specalities = providers?.specalities;
            if (specalities) {
                let filtered = getResults(searchKey, specalities, 'name').concat(getResults(searchKey, specalities, 'name_ar'))
                if (filtered?.length) {
                    var dataArr = filtered.map(item => {
                        return [JSON.stringify(item), item]
                    });
                    var maparr = new Map(dataArr);
                    var result = [...maparr.values()];
                    array.push({ key: 'Specialties', array: result, flag: 'specialty' })
                }
            }
            let doctors = providers?.providers;
            if (doctors) {
                let filtered = getResults(searchKey, doctors, 'name').concat(getResults(searchKey, doctors, 'name_ar'))
                if (filtered?.length) {
                    array.push({ key: 'Doctors', array: [...new Set(filtered)], flag: 'doctor' })
                }
            }
            let facilities = providers?.facilities;
            if (facilities) {
                let filtered = getResults(searchKey, facilities, 'facilityName').concat(getResults(searchKey, facilities, 'facilityName_ar'))
                if (filtered?.length) {
                    array.push({ key: 'Facilities', array: [...new Set(filtered)], flag: 'facility' })
                }
            }
        }
        return (
            <div style={{ backgroundColor: '#cacaca30' }}>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    <Input onChange={(e, { value }) => this.setState({ searchKey: value })} style={{ width: '90%' }} fluid />
                    <div onClick={() => {
                        this.setState({ search: !search })
                        window.history.replaceState(null, null, "/");
                    }
                    }><Icon name='times' style={{ color: '#fff' }} /></div>
                </div>
                {
                    array.map(record => {
                        return (
                            <div key={record.key} style={{ textAlign: 'initial' }}>
                                <div style={{ padding: '8px', fontFamily: 'tfBold', backgroundColor: '#fff', position: 'sticky', top: '51px', fontSize: '16px', color: '#4d4d4d' }}>{Strings[record.key]}</div>
                                {
                                    record?.array.map((item, index) => {
                                        return (
                                            <Link to={
                                                record.flag === 'doctor' ?
                                                    `/doctor-profile?key=${item.id}`
                                                    : record.flag === 'specialty' ?
                                                        `/searchView?search_type=doctors&speciality=${item.id}`
                                                        : record.flag === 'facility' ?
                                                            `/facility-profile?key=${item.facilityId}`
                                                            : ''
                                            }
                                                key={item.id + '_' + index}
                                                style={{
                                                    padding: '16px 12px',
                                                    fontSize: '16px',
                                                    fontFamily: 'tfMedium',
                                                    borderBottom: '1px solid #cacaca',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    color: '#4d4d4d'
                                                }}>
                                                <div>
                                                    {getName(item, "name") || getName(item, "facilityName")}
                                                </div>
                                                <div style={{ fontSize: '12px', color: '#cacaca', textTransform: 'capitalize' }}>{record.key !== 'Top Specialties' ? Strings[record?.flag] : ''}</div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}
const mapStateToProps = ({ providers, user }) => {
    return {
        providers, user: user?.user
    };
};
export default connect(mapStateToProps, {})(Home);

